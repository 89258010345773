import { useEffect } from 'react';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import React, { useState } from 'react';
import Cookies from 'js-cookie';
import Header from 'parts/Header';
import PageWrapper from 'parts/PageWrapper';
import PaddingWrapper from 'parts/PaddingWrapper';
import MovieList from 'components/MovieList';
import TvShowList from 'components/TvShowList';
import Loader from 'components/UI/Loader';
import setSelectedMenuItemName from 'actions/setSelectedMenuItemName';
import getStaticCategoryMovies from 'actions/getStaticCategoryMovies';
import getStaticCategoryTvShows from 'actions/getStaticCategoryTvShows';
import clearMovies from 'actions/clearMovies';
import STATIC_MOVIE_CATEGORIES from 'utils/constants/static-movie-categories';
import QUERY_PARAMS from 'utils/constants/query-params';
import LINKS from 'utils/constants/links';
import checkEmptyObject from 'utils/helpers/checkEmptyObject';
import getGenres from '../actions/getGenres';
import getConfig from '../actions/getConfig';

import { TMDB_API_KEY, TMDB_API_BASE_URL, TMDB_API_VERSION } from 'config/tmdb';

const Home = () => {
  const dispatch = useDispatch();
  const general = useSelector(state => state.general);
  const movies = useSelector(state => state.movies);
  const { query } = useRouter();

  const [selectedMediaType, setSelectedMediaType] = useState(Cookies.get('selectedMediaType'));

  const categoryName = query[QUERY_PARAMS.CATEGORY];
  const page = Number(query[QUERY_PARAMS.PAGE]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedMenuItemName());
      dispatch(clearMovies());
    };
  }, [dispatch]);

  useEffect(() => {
    if (Router.router.asPath !== LINKS.HOME.HREF && checkEmptyObject(query)) return;

    const initialCategoryName = Router.query[QUERY_PARAMS.CATEGORY];
    const initialPage = Router.query[QUERY_PARAMS.PAGE];

    if (!initialCategoryName && !initialPage) {
      const newCategoryName = STATIC_MOVIE_CATEGORIES[0].name;
      const newPage = 1;
      Router.replace({
        query: {
          [QUERY_PARAMS.CATEGORY]: newCategoryName,
          [QUERY_PARAMS.PAGE]: newPage
        }
      });
    } else if (!initialCategoryName && initialPage) {
      const newCategoryName = STATIC_MOVIE_CATEGORIES[0].name;
      const newPage = initialPage;
      Router.replace({
        query: {
          [QUERY_PARAMS.CATEGORY]: newCategoryName,
          [QUERY_PARAMS.PAGE]: newPage
        }
      });
    } else if (initialCategoryName && !initialPage) {
      const newCategoryName = initialCategoryName;
      const newPage = 1;
      Router.replace({
        query: {
          [QUERY_PARAMS.CATEGORY]: newCategoryName,
          [QUERY_PARAMS.PAGE]: newPage
        }
      });
    }
  }, [dispatch, query]);

  useEffect(() => {
    (async () => {
      if (!categoryName || !page) return;

      scroll.scrollToTop({ smooth: true });

      dispatch(setSelectedMenuItemName(categoryName));
      if (selectedMediaType === 'movie') {
        dispatch(getStaticCategoryMovies(categoryName, page));
        dispatch(getGenres());
      } else {
        dispatch(getStaticCategoryTvShows(categoryName, page));
        dispatch(getGenres());
      }
    })();
  }, [categoryName, page, selectedMediaType, dispatch]);

  const handleToggle = () => {
    const newMediaType = selectedMediaType === 'movie' ? 'tv' : 'movie';
    setSelectedMediaType(newMediaType);

    Cookies.set('selectedMediaType', newMediaType);
  };

  const { secure_base_url: baseUrl } = general.base.images;

  // Use useEffect to load GA4 script
  useEffect(() => {
    // Load the Google Analytics script dynamically
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-8SDD3QZDM4';
    script.async = true;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-8SDD3QZDM4');

    return () => {
      // Cleanup the script if component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Head>
        <title>{`${selectedMediaType === 'movie' ? 'Movies' : 'TV Shows'} | My App`}</title>
      </Head>
      <button onClick={handleToggle}>
        Switch to {selectedMediaType === 'movie' ? 'TV Shows' : 'Movies'}
      </button>
      
      {movies.loading ? (
        <Loader />
      ) : (
        <PageWrapper>
          {selectedMediaType === 'movie' ? (
            <MovieList movies={movies} baseUrl={baseUrl} />
          ) : (
            <TvShowList tvShows={movies} baseUrl={baseUrl} />
          )}
        </PageWrapper>
      )}
    </>
  );
}

export default Home;
